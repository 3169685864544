import { Component, input } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSelectModule } from '@angular/material/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { AssetPricePipe } from 'src/app/core/pipes/asset-price.pipe'
import { LegacyImageProcessorDirective } from 'src/app/core/directives/legacy-image-processor.directive'
import { BadgesContainerComponent } from '../badge-container/badges-container.component'
import { IAsset } from 'src/app/core/interfaces/asset.interface'
import { AssetMeterPipe } from '../../core/pipes/asset-meter.pipe'
import { IComponentConfig } from 'src/app/core/interfaces/config.interface'
import { LegacyImageProcessorTypes } from 'src/app/core/interfaces/image.interface'
import { CurrencyCode } from 'src/app/core/interfaces/currency.interface'


@Component({
  selector: 'app-asset-card-mini',
  templateUrl: './asset-card-mini.component.html',
  styleUrls: ['./asset-card-mini.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    LegacyImageProcessorDirective,
    BadgesContainerComponent,
    AssetPricePipe,
    AssetMeterPipe,
  ]
})
export class AssetCardMiniComponent {

  asset = input<IAsset>()
  currencyCode = input<CurrencyCode>(CurrencyCode.USD)
  defaultImage = input<string>('')
  badgesConfig = input<IComponentConfig>()

  LegacyImageServiceTypes = LegacyImageProcessorTypes

}
