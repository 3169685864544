<mat-form-field>
  <mat-label>{{label()}}</mat-label>
  <input
    matInput
    #dataInput
    [type]="type()"
    [step]="step()"
    [min]="min()"
    [max]="max()"
    [defaultValue]="defaultValue"
    [maxLength]="maxLength() || null"
    (change)="handleChange($event)"
    [formControl]="control"
    [required]="required()"
    [pattern]="pattern()"
  />
  @if (control.hasError('pattern')) {
    <mat-error>{{patternErrorMessage()}}</mat-error>
  }
  <div matSuffix>
    <ng-container *ngTemplateOutlet="suffixTemplate ?? defaultSuffixTemplate"></ng-container>
  </div>
</mat-form-field>

<ng-template #defaultSuffixTemplate></ng-template>