import { Directive, ElementRef, Input, OnInit } from '@angular/core'


@Directive({
  selector: '[selectableOnOpen]',
})
export class SelectableOnOpenDirective implements OnInit {
  constructor(public eleRef: ElementRef) {}
  @Input() selectOnOpen: boolean = false
  ngOnInit(): void {
    if (this.selectOnOpen) {
      this.eleRef.nativeElement.select()
    }
  }
}
