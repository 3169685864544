import { CommonModule } from '@angular/common'
import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { ButtonModule } from 'src/app/components/button/button.module'
import { IConfirmDialogOptions } from 'src/app/core/interfaces/dialog.interface'


@Component({
  selector: 'admin-confirm-dialog-component',
  template: `
    @if (data.title) {
      <div mat-dialog-title class="dialog__title">{{data.title}}</div>
    }
    @if (data.content) {
      <mat-dialog-content class="dialog__content">
        {{data.content}}
      </mat-dialog-content>
    }
    <mat-dialog-actions class="dialog__actions">
      <app-button btn-medium btn-primary btn-outlined mat-dialog-close [mat-dialog-close]="'cancel'">{{data.cancelLabel}}</app-button>
      <app-button btn-medium btn-primary btn-contained mat-dialog-close [mat-dialog-close]="'confirm'">{{data.confirmLabel}}</app-button>
    </mat-dialog-actions>
  `,
  styleUrls: ['../../../../assets/admin.scss'],
  styles: [`
    :host {
      display: block;
    }
  `],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDialogModule,
    ButtonModule,
  ],
})
export class AdminConfirmDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IConfirmDialogOptions) {}
}
